<template>
  <div class="wrapper" ref="element_ref">
     <!--components/Layout/LeftSideNav.vue-->
    <div id="sidebar">
      <div class="">
        <ul class="left-side-nav">
          <li v-for="(menu, index) in menuItems" :key="index">
            <NuxtLink 
              no-prefetch 
              :to="menu.link" 
              :title="menu.title" 
              data-bs-toggle="tooltip" 
              data-bs-placement="right" 
              :class="{ 'active-nav': isHoverd(index) }" 
              @click="force_rerender_router_without_reload(menu.link, $event)"
              v-if="((menu.link != '/add-tenant') || (is_supremo))"
              :disabled="! page_mounted_state"
            >
              <i :class="menu.icon" aria-hidden="true"> </i>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
const page_mounted_state = usePageMountedState();
// <i class="fa-light fa-chart-mixed"></i>
const menuItems = [
  //{ title: "Sessions", icon: "fal fa-lg fa-comments", link: "/member" },
  //{ title: "Show All Dags", icon: "fal fa-lg fa-sitemap", link: "/dags?convId=__dags" },
  //{ title: "Dashboard", icon: "fal fa-lg fa-analytics", link: "/dashboard" },
  // { title: "Upload", icon: "fal fa-lg fa-upload", link: "/upload" },
  { title: "Home", icon: "fal fa-lg fa-house-user", link: "/" },
  { title: "User Management", icon: "fal fa-lg fa-users-cog class_nav_icon", link: "/n/user-management" },
  { title: "Adjust Settings", icon: "fal fa-lg fa-sliders-h", link: "/vsettings" },
  { title: "Integrations", icon: "fal fa-lg fa-puzzle", link: "/vintegrations" },
  { title: "Sessions", icon: "fal fa-lg fa-square-terminal", link: "/cli-sessions/all" },
  { title: "Dashboards", icon: "fal fa-lg fa-light fa-chart-mixed", link: "/n/jobs" },
  //{ title: "Tutorials", icon: "fal fa-lg fa-book", link: "/member/tutorials" },
];

const route = useRoute();
const isHoverd = (index) => {
  return menuItems[index].link?.includes(route.name);
};

let element_ref = ref(null);
onMounted(() => {
  initializeTooltips(element_ref.value);
});

const force_rerender_route_state = useForceRerenderRouteState();
const user_management_tab_state = useUserManagementTabState();

let is_supremo = false;
if (typeof(window) != 'undefined') {
  let role = localStorage.getItem('role');
  if ((role != null) && (role.toLowerCase() == 'supremo')) {
    is_supremo = true;
  }
}

const force_rerender_router_without_reload = (url, event) => {
  /*
  Commenting this out.  Sarang typically click on the home icon and without waiting the the page to load, 
  he then click on some other button which change the URL to a different page, and then when this code finish, 
  it display the home page.  The force_rerender_route_state mechanism below seems faster than the full reload 
  we are doing here, perhaps not ideal, but if it works (not all situations)....
  if (url == '/') {
    // If the user clicks on the home icon, do a full reload
    window.location = '/';
  }
  */

  hideTooltips(element_ref.value, false);

  const route = useRoute();
  let current_url = route.fullPath;
  if (url == current_url) {
    const router = useRouter();
    /*
    On the "Add Tenant", or the "User Dashboard" page, we first display the initial component, and then as the user interact with page, 
    we hide the initial component, and show the "result" component (the URL of the page never change).  If the user want to see the initial component / view again, in the 
    legacy application, the user is expected to click on the icon for the page in the left hand side.  However, with Nuxt, because the URL is the same, Nuxt / Vue does 
    not re-render the page.  We want it to re-render the page without reloading the entire app.  I could not find any way to tell Nuxt or Vue to just re-render the route 
    without reloading the entire app.  

    What I end up doing is creating the useForceRerenderRouteState composable / state, and inside add-tenant.vue and user-dashboard.vue, I add the :key property 
    (search these pages for force_rerender_route_).  Inside these pages, I also add a watcher to watch force_rerender_route_state, and when the value for this state 
    is changed, we reset some reactive variables, and call other statements that are specific to these pages using a setTimeout.  The setTimeout is needed because 
    otherwise, the my_raw_div is still missing from the DOM or is not ready yet.  The value that I used with setTimeout is just 1 milliseconds, and that seems to work
    fine, but we may need to bump it up.
    */

    force_rerender_route_state.value = force_rerender_route_state.value + 1;
    /*
    Temporarily comment out these two lines to see if that fix an issue.  Specifically, I think we do need to check the URL of the page, and 
    if it matches certain pattern, then we can safely set the value for user_management_tab_state to 'users'.

    user_management_tab_state.value = 'users';
    */
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: stretch;
}
#sidebar {
  background-color: white !important;
  position: fixed;
  top: 48px;
  left: 0;
  height: 100%;
  width: 50px;
  overflow: auto;
  border-right: 1px solid #ccc;
}

.left-side-nav {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.left-side-nav li {
  text-align: center;
  padding: 5px;
}

.left-side-nav li a {
  display: inline-block;
  text-align: center;
  font-size: 1.1em;
  color: gray;
  padding: 8px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.left-side-nav li a:hover,
.active-nav {
  background-color: #0D64E2;
  color: #fff !important;
}
</style>
